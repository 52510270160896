<template>
  <div>
    <ui-loading v-if="loading" inline class="py-5 my-5" />
    <ui-data-table
      v-else
      :columns="visibleColumns"
      :data="staffs"
      :column-class="getColumnClass"
      data-table="staff"
      separatable
    >
      <template #staff-info="{ row }">
        <ui-icon-button
          name="info"
          size="16px"
          class="text-primary"
          @click="toggleInfo(true, row)"
        />
      </template>

      <template #staff-qr="{ row }">
        <a
          v-if="row.status !== 'NOT_ACTIVE'"
          href="javascript:void(0)"
          class="text-dark"
          @click="toggleQr(true, row)"
        >
          <ui-icon
            name="qr"
            size="16"
          />
        </a>
        <ui-icon
          v-else
          name="qr"
          size="16"
        />
      </template>

      <template #staff-name="{ row, value }">
        {{ value }}
        <span v-if="row.isOwner">*</span>
      </template>

      <template #staff-location="{ row }">
        <router-link
          class="text-primary word-break-word"
          :to="{
            name: 'location-view',
            params: {
              locationId: row.location.id,
            },
          }"
        >
          {{ row.location.name }}
        </router-link>
      </template>

      <template #staff-amount-value="{ row, value }">
        {{ value | money(row.location.currencyCode) }}
      </template>

      <template #staff-kyc="{ value }">
        <div class="d-flex align-items-center justify-content-center">
          <template v-if="value.cardStatus && value.cardTypes[value.cardStatus] && value.isCardVisible">
            <div class="d-flex align-items-center">
              <ui-tooltip placement="top">
                <template #content>
                  <div class="d-flex flex-column align-items-center">
                    <span>{{ value.tooltipText }}</span>
                    <span>{{ value.cardDate | date('dd/LL/yyyy') }}</span>
                  </div>
                </template>
                <ui-icon
                  :name="value.cardTypes[value.cardStatus].name"
                  :width="value.cardTypes[value.cardStatus].width"
                  :height="value.cardTypes[value.cardStatus].height"
                />
              </ui-tooltip>
            </div>
            <div class="d-flex align-items-center ml-2">
              {{ $t(`cardStatus.${value.cardStatus}`) }}
            </div>
          </template>

          <staff-table-k-y-c-tooltip
            v-else-if="value.isIbanCompleted"
            icon="iban-completed"
            label="IBAN completed"
          />

          <staff-table-k-y-c-tooltip
            v-else-if="value.onboardingStatus"
            icon="onboarding-completed"
            label="Onboarding completed"
          />

          <div v-else class="d-flex align-items-center ml-2">
            -
          </div>
        </div>
      </template>

      <template #staff-shared-card="{ value }">
        <ui-popover
          v-if="value.isInUse"
          placement="top"
          trigger="hover"
          icon="debit-card"
          icon-width="30px"
          icon-height="20px"
        >
          Shared card in use
        </ui-popover>

        <ui-popover
          v-else-if="value.isAllowed"
          placement="top"
          trigger="hover"
          icon="check"
          icon-size="12px"
        >
          Shared card allowed
        </ui-popover>

        <ui-popover
          v-else
          placement="top"
          trigger="hover"
          icon="dash"
          icon-size="12px"
          class="disabled"
        >
          Shared card disabled
        </ui-popover>
      </template>

      <template #staff-shared-bank-account="{ value }">
        <ui-popover
          v-if="value.isInUse"
          placement="top"
          trigger="hover"
          icon="bank-success"
          icon-width="24px"
          icon-height="24px"
        >
          Shared bank account in use
        </ui-popover>

        <ui-popover
          v-else-if="value.isAllowed"
          placement="top"
          trigger="hover"
          icon="bank-check"
          icon-size="25px"
          icon-height="24px"
        >
          Shared bank account allowed
        </ui-popover>

        <ui-popover
          v-else
          placement="top"
          trigger="hover"
          icon="dash"
          icon-size="12px"
          class="disabled"
        >
          Shared bank account disabled
        </ui-popover>
      </template>

      <template #staff-employment-period="{ row }">
        <div class="d-flex flex-column">
          <div v-if="row.startWorkDate" class="d-flex align-items-center">
            <span>{{ row.startWorkDate | date('dd/LL/yyyy') }}</span>

            <ui-tooltip placement="top">
              <template #content>
                <span v-html="$t('employmentPeriodTooltip.startDate')" />
              </template>
              <i class="nc-icon nc-alert-circle-i label-info ml-2 font-16 align-self-start"></i>
            </ui-tooltip>
          </div>
          <div v-if="row.endWorkDate" class="d-flex align-items-center">
            <span>{{ row.endWorkDate | date('dd/LL/yyyy') }}</span>

            <ui-tooltip placement="bottom">
              <template #content>
                <span v-html="$t('employmentPeriodTooltip.endDate')" />
              </template>
              <i class="nc-icon nc-alert-circle-i label-info ml-2 font-16 align-self-start"></i>
            </ui-tooltip>
          </div>
          <div v-else>
            <span class="text-gray">{{ $t('table.empty.employmentPeriod') }}</span>
          </div>
        </div>
      </template>

      <template #staff-action="{ row }">
        <div v-if="isStaffLoading(row)" class="position-relative px-3 ml-n2">
          <ui-loading />
        </div>
        <ui-action-down
          v-else-if="isStaffActionsEnabled(row)"
          v-protected
          :items="getStaffActions(row)"
          value-original
          @select="handleAction($event, row)"
          @opened="handleOpenActions(row)"
        >
          <template #resendInvitation="{ label }">
            <div class="d-flex align-items-center">
              <span :class="{ disabled: registrationMessage.disabled }">
                {{ label }}
              </span>
              <ui-loading
                v-if="registrationMessage.remainingAttempts === null"
                class="ml-4"
                inline
              />
              <span
                v-else-if="registrationMessage.timer"
                class="ml-4"
              >
                {{ registrationMessage.timer }}
              </span>
            </div>
          </template>
        </ui-action-down>
      </template>

      <template #staff-info-button="{ row }">
        <ui-button
          type="primary"
          class="px-2 py-2"
          size="sm"
          native-type="button"
          outline
          action
          @click="toggleInfo(true, row)"
        >
          Read more
        </ui-button>
      </template>

      <template v-if="hasPages || showOwnerMark" #after>
        <div class="my-4 d-flex align-items-center">
          <ui-pagination
            v-if="hasPages"
            :value="page"
            :total="total"
            :per-page="pageSize"
            @input="$emit('update:page', $event)"
          />
          <div v-if="showOwnerMark" class="ml-auto mr-4">
            * Owner
          </div>
        </div>
      </template>
    </ui-data-table>

    <staff-info-modal
      v-if="showInfo.staff"
      title="Staff additional info"
      :show="showInfo.show"
      :staff="showInfo.staff"
      :location="showInfo.staff.location"
      :attributes="showInfo.attributes"
      :buttons="showInfo.buttons"
      @closed="toggleInfo(false)"
    />

    <qr-code
      v-if="showQr.staff"
      v-bind="showQr.staff"
      :show="showQr.show"
      @closed="toggleQr(false)"
    />

    <staff-edit-modal
      v-if="showEdit.staff"
      :title="showEdit.staff.fio || showEdit.staff.login"
      :show="showEdit.show"
      :staff="showEdit.staff"
      :location="showEdit.staff.location"
      @apply="showEdit.handle(true)"
      @cancel="showEdit.handle(false)"
    />

    <staff-deactivate-modal
      v-if="showDeactivate.staff"
      :show="showDeactivate.show"
      :staff="showDeactivate.staff"
      :location="showDeactivate.staff.location"
      @apply="showDeactivate.handle(true)"
      @cancel="showDeactivate.handle(false)"
    />

    <staff-groups-modal
      v-if="showGroups.staff"
      :show="showGroups.show"
      :staff="showGroups.staff"
      :location="showGroups.staff.location"
      :groups="showGroups.staff.location.groups"
      @apply="showGroups.handle(true)"
      @cancel="showGroups.handle(false)"
    />

    <staff-info-modal
      v-if="showRegistrationDetails.staff"
      title="Registration details"
      :show="showRegistrationDetails.show"
      :staff="showRegistrationDetails.staff"
      :location="showRegistrationDetails.staff.location"
      :attributes="showRegistrationDetails.attributes"
      :buttons="showRegistrationDetails.buttons"
      @closed="showRegistrationDetails.handle"
    />

    <staff-info-modal
      v-if="showIdInformation.staff"
      title="ID information"
      :show="showIdInformation.show"
      :staff="showIdInformation.staff"
      :location="showIdInformation.staff.location"
      :attributes="showIdInformation.attributes"
      :buttons="showIdInformation.buttons"
      @closed="showIdInformation.handle"
    />

    <staff-info-modal
      v-if="showBankAccountDetails.staff"
      title="Bank account details"
      :show="showBankAccountDetails.show"
      :staff="showBankAccountDetails.staff"
      :location="showBankAccountDetails.staff.location"
      :attributes="showBankAccountDetails.attributes"
      :buttons="showBankAccountDetails.buttons"
      @closed="showBankAccountDetails.handle"
    />

    <staff-payroll-modal
      v-if="showAddPayrollId.staff"
      :show="showAddPayrollId.show"
      :staff="showAddPayrollId.staff"
      :location="showAddPayrollId.staff.location"
      @apply="showAddPayrollId.handle"
      @cancel="showAddPayrollId.handle(false)"
    />

    <shared-card-terms-modal
      v-model="showSharedCardTerms.show"
      @apply="showSharedCardTerms.handle(true)"
      @cancel="showSharedCardTerms.handle(false)"
    />

    <user-change-phone-modal
      v-if="changePhoneModal.show"
      :user="changePhoneModal.staff.user"
      @close="changePhoneModal.show = false"
    />
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { capitalize } from 'lodash';
import { ALL_TAX_REPORTING_TYPES } from '@/config/taxReportingTypes';
import Timer from '@/class/Timer';
import api from '@/api';
import getFio from '@/utils/fio';
import parseDate from '@/utils/parseDate';
import QrCode from '@/components/QrCode.vue';
import SharedCardTermsModal from '@/components/SharedCardTermsModal.vue';
import StaffTableKYCTooltip from '@/components/Staff/StaffTableKYCTooltip.vue';
import StaffInfoModal from '@/components/Staff/StaffInfoModal.vue';
import StaffEditModal from '@/components/Staff/StaffEditModal.vue';
import StaffDeactivateModal from '@/components/Staff/StaffDeactivateModal.vue';
import StaffGroupsModal from '@/components/Staff/StaffGroupsModal.vue';
import StaffPayrollModal from '@/components/Staff/StaffPayrollModal.vue';
import UserChangePhoneModal from '@/components/User/UserChangePhoneModal.vue';
import { PAYMENT_SYSTEM, PAYMENT_SYSTEM_LABEL } from '@/config/paymentSystems';

export const STAFF_TABLE_COLUMNS = [
  'INFO',
  'QR',
  'PAYROLL_ID',
  'PAYOUT_ID',
  'NAME',
  'LOCATION_NAME',
  'TIPS_AMOUNT',
  'STAFF_TYPE',
  'TAX_REPORTING',
  'PAYMENT_STATUS',
  'PAYMENT_SYSTEM',
  'PAYOUT_PLAN_NAME',
  'PAYOUT_PLAN_PERIOD',
  'USER_TYPE',
  'SHARED_CARD',
  'SHARED_BANK_ACCOUNT',
  'EMPLOYMENT_PERIOD',
  'ACTION',
  'READ_MORE',
];

export const STAFF_TABLE_ACTIONS = [
  'EDIT',
  'DELEGATE',
  'UNDELEGATE',
  'DEACTIVATE',
  'MOVE_GROUP',
  'REGISTRATION_DETAILS',
  'ID_INFORMATION',
  'BANK_ACCOUNT_DETAILS',
  'VIEW_AS',
  'SEND_REQUEST_ID',
  'CANCEL_REQUEST_ID',
  'SEND_REQUEST_BANK_DETAILS',
  'CANCEL_REQUEST_BANK_DETAILS',
  'ADD_PAYROLL_ID',
  'RESET_STRIPE',
  'ALLOW_SHARED_CARD',
  'DISABLE_SHARED_CARD',
  'RESEND_INVITATION',
  'CHANGE_PHONE',
  'RESET_OTP_TRYOUTS',
  'ACTIVATE_NYM_CARD',
  'SUSPEND_NYM_CARD',
  'DISABLE_NYM_CARD',
];

export const STAFF_CHECKOUT_GATEWAY = [
  PAYMENT_SYSTEM.CHECKOUT_UK_DASHBOARD,
  PAYMENT_SYSTEM.CHECKOUT,
];

export const STAFF_PAYERMAX_GATEWAY = [
  PAYMENT_SYSTEM.PAYER_MAX,
];

export const STAFF_RYFT_GATEWAY = [
  PAYMENT_SYSTEM.RYFT,
];

const MINUTES_2 = 2 * 60 * 1000;

export default {
  name: 'StaffListTable',

  components: {
    StaffTableKYCTooltip,
    QrCode,
    SharedCardTermsModal,
    StaffInfoModal,
    StaffEditModal,
    StaffDeactivateModal,
    StaffGroupsModal,
    StaffPayrollModal,
    UserChangePhoneModal,
  },

  props: {
    staffs: {
      type: Array,
      required: true,
    },
    columns: {
      type: Array,
      required: true,
      validator: (value) => {
        return value.every((col) => STAFF_TABLE_COLUMNS.includes(col));
      },
    },
    actions: {
      type: Array,
      required: true,
      validator: (value) => {
        return value.every((action) => STAFF_TABLE_ACTIONS.includes(action));
      },
    },
    total: {
      type: Number,
      required: true,
    },
    page: {
      type: Number,
      required: true,
    },
    pageSize: {
      type: Number,
      required: false,
      default: 10,
    },
    loading: Boolean,
  },

  data: () => ({
    nymCardTypes: {
      ACTIVE: {
        name: 'card-icon',
        width: '20',
        height: '14',
      },
      SUSPENDED: {
        name: 'card-icon-suspended',
        width: '24',
        height: '18',
      },
      EXPIRED: {
        name: 'card-icon-expired',
        width: '24',
        height: '18',
      },
      TERMINATED: {
        name: 'card-icon-terminated',
        width: '24',
        height: '18',
      },
    },
    ryftAccountTypes: {
      ACTIVE: {
        name: 'ryft-added',
        width: '24',
        height: '24',
      },
      SUSPENDED: {
        name: 'card-icon-suspended',
        width: '24',
        height: '18',
      },
      EXPIRED: {
        name: 'card-icon-expired',
        width: '24',
        height: '18',
      },
      TERMINATED: {
        name: 'card-icon-terminated',
        width: '24',
        height: '18',
      },
    },
    checkoutCardTypes: {
      ACTIVE: {
        name: 'checkout-card-icon',
        width: '24',
        height: '24',
      },
      SUSPENDED: {
        name: 'checkout-card-icon-suspended',
        width: '24',
        height: '24',
      },
      EXPIRED: {
        name: 'checkout-card-icon-expired',
        width: '24',
        height: '24',
      },
      BLOCKED: {
        name: 'checkout-card-icon-blocked',
        width: '24',
        height: '24',
      },
    },
    showQr: {
      show: false,
      staff: null,
    },
    showInfo: {
      show: false,
      staff: null,
      attributes: [
        // Filling on opening.
      ],
      buttons: [
        'COPY_INFO',
        'VIEW_AS',
      ],
    },
    staffsLoading: [],
    showEdit: {
      show: false,
      staff: null,
      handle: null,
    },
    showRegistrationDetails: {
      show: false,
      staff: null,
      attributes: [
        'NAME',
        'STAFF_PAYOUT_ID',
        'LOCATION_NAME',
        'EMAIL',
        'PHONE_NUMBER',
        'STAFF_TYPE',
        'TAX_REPORTING',
        'TIPS_TYPE',
        'PAYMENT_SYSTEM',
        'PAYMENT_STATUS',
        'SHARED_CARD',
        'APP_VERSION',
        'STRIPE_ACCOUNT',
        'ID_PHOTO_SCAN',
      ],
      buttons: [
        'COPY_INFO',
        'CLOSE',
      ],
    },
    showIdInformation: {
      show: false,
      staff: null,
      attributes: [
        'FULL_LEGAL_NAME',
        'BIRTH_DATE',
        'PASSPORT_ID',
        'ID_PHOTO_FRONT',
        'ID_PHOTO_BACK',
        'COUNTRY_ID',
        'EMAIL',
        'PHONE_NUMBER',
      ],
      buttons: [
        'COPY_INFO',
        'CLOSE',
      ],
    },
    showDeactivate: {
      show: false,
      staff: null,
      handle: null,
    },
    showGroups: {
      show: false,
      staff: null,
      handle: null,
    },
    showAddPayrollId: {
      show: false,
      staff: null,
      handle: null,
    },
    showSharedCardTerms: {
      show: false,
      staff: null,
      handle: null,
    },
    changePhoneModal: {
      show: false,
      staff: null,
    },
    registrationMessage: {
      timer: null,
      remainingAttempts: null,
      disabled: true,
    },
    showBankAccountDetails: {
      show: false,
      staff: null,
      attributes: [
        'FIRST_NAME',
        'LAST_NAME',
        'BIRTH_DATE',
        'NATIONALITY',
        'ADDRESS',
        'EMAIL',
        'BANK_NAME',
        'IBAN',
        'SWIFT_OR_BIC',
        'ID_PHOTO_SCAN',
      ],
      buttons: [
        'COPY_INFO',
        'CLOSE',
      ],
    },
  }),

  computed: {
    ...mapState('user', [
      'profile',
    ]),
    ...mapGetters({
      isTroncStaff: 'auth/isTroncStaff',
      isViewAsAccess: 'auth/isViewAsAccess',
      isSupportAdmin: 'auth/isSupportAdmin',
    }),
    allColumns() {
      return [
        {
          id: 'INFO',
          name: 'staff-info',
        },
        {
          id: 'QR',
          name: 'staff-qr',
        },
        {
          id: 'PAYROLL_ID',
          name: 'payroll-id',
          label: this.$t('table.columns.payrollId'),
          prop: 'payrollId',
          empty: this.$t('table.empty.payrollId'),
        },
        {
          id: 'PAYOUT_ID',
          name: 'staff-id',
          label: this.$t('table.columns.easytipId'),
          prop: 'payoutId',
          empty: this.$t('table.empty.easytipId'),
        },
        {
          id: 'NAME',
          name: 'staff-name',
          label: this.$t('table.columns.name'),
          prop: 'name',
          value: ({ row }) => row.fio || getFio(row) || row.login,
          empty: this.$t('table.empty.name'),
        },
        {
          id: 'LOCATION_NAME',
          name: 'staff-location',
          label: this.$t('table.columns.locationName'),
          prop: 'location',
          value: ({ row }) => row.location?.name,
          empty: this.$t('table.empty.locationName'),
        },
        {
          id: 'TIPS_AMOUNT',
          name: 'staff-amount',
          label: this.$t('table.columns.amountTips'),
          prop: 'transactionsAmount',
          empty: this.$t('table.empty.amountTips'),
        },
        {
          id: 'STAFF_TYPE',
          name: 'staff-type',
          label: this.$t('table.columns.staffType'),
          prop: 'staffType',
          value: ({ row }) => this.getStaffTypeValue(row),
          empty: this.$t('table.empty.staffType'),
        },
        {
          id: 'TAX_REPORTING',
          name: 'staff-reporting-status',
          label: 'Tax reporting',
          prop: 'taxReportingType',
          value: ({ row }) => this.getTaxReportingTypeValue(row),
          empty: 'No status',
        },
        {
          id: 'PAYMENT_STATUS',
          name: 'staff-kyc',
          label: this.$t('table.columns.paymentStatus'),
          value: ({ row }) => {
            const isCheckoutCard = STAFF_CHECKOUT_GATEWAY.includes(row?.paymentSystem || row.gateway);
            const isPayermax = STAFF_PAYERMAX_GATEWAY.includes(row?.paymentSystem || row.gateway);
            const isRyft = STAFF_RYFT_GATEWAY.includes(row?.paymentSystem || row.gateway);

            let cardTypes;
            let tooltipText;
            if (isRyft) {
              cardTypes = this.ryftAccountTypes;
              tooltipText = 'Ryft IBAN added';
            } else if (isCheckoutCard) {
              cardTypes = this.checkoutCardTypes;
              tooltipText = 'CheckoutCard added';
            } else {
              cardTypes = this.nymCardTypes;
              tooltipText = 'NymCard added';
            }

            return {
              isCheckoutCard,
              cardStatus: row?.cardStatus
                ? row.cardStatus
                : row?.user?.card?.cardStatus,
              cardDate: row?.addCardDate
                ? row.addCardDate
                : row?.user?.card?.addCardDate,
              cardTypes,
              tooltipText,
              paymentStatus: row.paymentStatus,
              onboardingStatus: row.firstName && row.lastName && row.email,
              isExistsActiveNymCard: row.isExistsActiveNymCard,
              isCardVisible: !isPayermax,
              isIbanCompleted: row.isIbanCompleted,
            };
          },
          empty: this.$t('table.empty.paymentStatus'),
        },
        {
          id: 'PAYMENT_SYSTEM',
          name: 'payment-system',
          label: this.$t('table.columns.paymentSystem'),
          prop: 'paymentSystem',
          value: ({ row }) => PAYMENT_SYSTEM_LABEL[row.paymentSystem],
          empty: this.$t('table.empty.paymentSystem'),
        },
        {
          id: 'PAYOUT_PLAN_NAME',
          name: 'staff-payout-plan',
          label: 'Payout plan',
          prop: 'currentPlan',
          value: ({ row }) => row.currentPlan?.name || '-',
          align: 'center',
        },
        {
          id: 'USER_TYPE',
          name: 'user-type',
          label: 'User type',
          prop: 'userType',
          value: ({ row }) => this.formatUserType(row.userType),
          align: 'center',
        },
        {
          id: 'PAYOUT_PLAN_PERIOD',
          name: 'staff-payout-period',
          label: 'Payout plan',
          prop: 'payoutPlanPeriod',
          value: ({ row }) => row.payoutPlanPeriod || '-',
          align: 'center',
        },
        {
          id: 'BANK',
          name: 'staff-bank',
          label: 'Bank',
          prop: 'bank',
          value: ({ row }) => row?.card?.issuer || '-',
          align: 'center',
        },
        {
          id: 'SHARED_CARD',
          name: 'staff-shared-card',
          label: 'Shared card',
          prop: 'isSharedCard',
          value: ({ row }) => {
            return {
              isInUse: row.isSharedCard && row.isAllowedUsedSharedCard,
              isAllowed: !row.isSharedCard && row.isAllowedUsedSharedCard,
            };
          },
          align: 'center',
        },
        {
          id: 'SHARED_BANK_ACCOUNT',
          name: 'staff-shared-bank-account',
          label: 'Shared bank account',
          prop: 'isSharedBankAccount',
          value: ({ row }) => {
            return {
              isInUse: row.isSharedBankAccount && row.isAllowedUsedSharedBankAccount,
              isAllowed: !row.isSharedBankAccount && row.isAllowedUsedSharedBankAccount,
            };
          },
          align: 'center',
        },
        {
          id: 'EMPLOYMENT_PERIOD',
          name: 'staff-employment-period',
          label: this.$t('table.columns.employmentPeriod'),
          prop: 'employmentPeriod',
          empty: this.$t('table.empty.employmentPeriod'),
        },
        {
          id: 'ACTION',
          name: 'staff-action',
        },
        {
          id: 'READ_MORE',
          name: 'staff-info-button',
        },
      ];
    },
    visibleColumns() {
      return this.columns
        .map((col) => this.allColumns.find((item) => item.id === col))
        .filter(Boolean);
    },
    showOwnerMark() {
      return this.staffs.filter(({ isOwner }) => isOwner).length > 0;
    },
    hasPages() {
      return this.total > this.pageSize;
    },
  },

  async mounted() {
    try {
      await this.getStaffTypes();
    } catch (e) {
      this.$showServerError(e);
    }
  },

  beforeDestroy() {
    this.resetResendInvitation();
  },

  methods: {
    ...mapActions('auth', [
      'loginAs',
    ]),
    ...mapActions('handbook', [
      'getStaffTypes',
    ]),
    formatUserType(str) {
      if (!str) {
        return 'Staff';
      }

      return capitalize(str.toLowerCase().split('_').join(''));
    },
    getColumnClass(col, row) {
      if (row.status !== 'ACTIVE') {
        return col.id === 'ACTION' ? '' : 'opacity-60';
      }

      return '';
    },
    getStaffTypeValue({
      staffType,
      isTronc,
      isTroncmaster,
      location,
    }) {
      return this.$getStaffTypeName(staffType)
        + (isTronc && isTroncmaster ? ` (${this.$t('form.labels.troncMaster', location.country)})` : '')
        + (isTronc && !isTroncmaster ? ` (Deputy ${this.$tl('form.labels.troncMaster', location.country)})` : '');
    },
    getTaxReportingTypeValue({ taxReportingType }) {
      return ALL_TAX_REPORTING_TYPES.find(({ value }) => value === taxReportingType)?.label || null;
    },
    getStaffTroncsCount() {
      return this.staffs.filter(({ isTronc, isTroncmaster, status }) => {
        return status === 'ACTIVE' && isTronc && !isTroncmaster;
      }).length;
    },
    toggleInfo(show, item = null) {
      if (show) {
        const attributes = [
          'EMAIL',
          'PHONE_NUMBER',
          'FULL_LEGAL_NAME',
          'BIRTH_DATE',
          'PASSPORT_ID',
          'COUNTRY_ID',
          'NATIONALITY',
          'ID_PHOTO_FRONT',
          'ID_PHOTO_BACK',
          'APP_INSTALLED',
          'APP_ENTRY_DATE',
          'USAGE_FREQUENCY',
          'RYFT_ACCOUNT_ID',
          'MONTHLY_REPORT',
        ];

        if (item.paymentSystem === PAYMENT_SYSTEM.STRIPE || item.stripeAccountId) {
          attributes.splice(2, 0, 'STRIPE_ACCOUNT');
        }

        this.showInfo.attributes = attributes;
      }

      this.showInfo.staff = item;
      this.showInfo.show = show;
    },
    toggleQr(show, item = null) {
      if (show) {
        this.showQr.staff = {
          staffId: item.id,
          locationId: item.location?.id,
          groupId: item.group?.id,
        };
      } else {
        this.showQr.staff = null;
      }

      this.showQr.show = show;
    },
    isStaffLoading({ id }) {
      return this.staffsLoading.some((value) => value === id);
    },
    setStaffLoading({ id }, isLoading) {
      if (isLoading) {
        this.staffsLoading.push(id);
      } else {
        this.staffsLoading = this.staffsLoading.filter((value) => value !== id);
      }
    },
    isStaffActionsEnabled({ status, login, isTroncmaster }) {
      if (isTroncmaster && this.isTroncStaff) {
        return false;
      }

      if (!this.profile.login) {
        return true;
      }

      return login !== this.profile.login;
    },
    getStaffActions({
      status,
      isTronc,
      location,
      userType,
      paymentSystem,
      paymentStatus,
      isAllowedButtonSharedCard,
      isAllowedUsedSharedCard,
    }) {
      return [
        {
          id: 'EDIT',
          method: 'editStaff',
          label: 'Edit',
          visible: status === 'ACTIVE',
          disabled: false,
        },
        {
          id: 'DELEGATE',
          method: 'delegate',
          label: `Share ${this.$tl('form.labels.troncMaster', location.country)} rights`,
          visible: !isTronc && status === 'ACTIVE',
          disabled: false,
        },
        {
          id: 'UNDELEGATE',
          method: 'undelegate',
          label: `Revoke ${this.$tl('form.labels.troncMaster', location.country)} rights`,
          visible: isTronc && status === 'ACTIVE',
          disabled: false,
        },
        {
          id: 'DEACTIVATE',
          method: 'openDeactivate',
          label: this.$t('form.buttons.deactivate'),
          visible: status === 'ACTIVE',
          disabled: false,
        },
        {
          id: 'MOVE_GROUP',
          method: 'openGroups',
          label: 'Move group',
          visible: location.paymentPage === 'GROUPS' && status === 'ACTIVE',
          disabled: false,
        },
        {
          id: 'REGISTRATION_DETAILS',
          method: 'openRegistrationDetails',
          label: 'Registration details',
          visible: !userType,
          disabled: false,
        },
        {
          id: 'ID_INFORMATION',
          method: 'openIdInformation',
          label: 'ID information',
          visible: true,
          disabled: false,
        },
        {
          id: 'BANK_ACCOUNT_DETAILS',
          method: 'openBankAccountDetails',
          label: 'Bank account details',
          visible: location.country === 'ae' && status === 'ACTIVE',
          disabled: false,
        },
        {
          id: 'VIEW_AS',
          method: 'viewAs',
          label: 'View as',
          visible: this.isViewAsAccess,
          disabled: false,
        },
        {
          id: 'SEND_REQUEST_ID',
          method: 'sendRequestId',
          label: 'Send request for ID',
          visible: true && status === 'ACTIVE',
          disabled: false,
        },
        {
          id: 'CANCEL_REQUEST_ID',
          method: 'cancelRequestId',
          label: 'Cancel request for ID',
          visible: true && status === 'ACTIVE',
          disabled: false,
        },
        {
          id: 'SEND_REQUEST_BANK_DETAILS',
          method: 'sendRequestBankDetails',
          label: 'Request bank info',
          visible: location.country === 'ae' && status === 'ACTIVE',
          disabled: false,
        },
        {
          id: 'CANCEL_REQUEST_BANK_DETAILS',
          method: 'cancelRequestBankDetails',
          label: 'Cancel bank info request',
          visible: location.country === 'ae' && status === 'ACTIVE',
          disabled: false,
        },
        {
          id: 'ADD_PAYROLL_ID',
          method: 'openAddPayroll',
          label: 'Add Payroll ID' && status === 'ACTIVE',
          visible: true,
          disabled: false,
        },
        {
          id: 'RESET_STRIPE',
          method: 'resetStripe',
          label: 'Reset Stripe',
          visible: paymentSystem === PAYMENT_SYSTEM.STRIPE
            && [
              'RESTRICTED',
              'PENDING',
              'REJECTED',
              'RESTRICTED_SOON',
            ].includes(paymentStatus)
            && status === 'ACTIVE',
          disabled: false,
        },
        {
          id: 'ALLOW_SHARED_CARD',
          method: 'openSharedCardTerms',
          label: 'Allow shared card',
          visible: !isAllowedUsedSharedCard
            && isAllowedButtonSharedCard
            && status === 'ACTIVE',
          disabled: false,
        },
        {
          id: 'DISABLE_SHARED_CARD',
          method: 'disallowSharedCard',
          label: 'Disable shared card',
          visible: isAllowedUsedSharedCard
            && isAllowedButtonSharedCard
            && status === 'ACTIVE',
          disabled: false,
        },
        {
          id: 'RESEND_INVITATION',
          method: 'resendInvitation',
          label: 'Resend invitation',
          visible: status === 'ACTIVE',
          disabled: this.registrationMessage.disabled,
        },
        {
          id: 'CHANGE_PHONE',
          method: 'changePhone',
          label: 'Change phone',
          visible: this.isSupportAdmin,
          disabled: false,
        },
        {
          id: 'RESET_OTP_TRYOUTS',
          method: 'resetOtpTryouts',
          label: 'Reset OTP tryouts',
          visible: status === 'ACTIVE',
          disabled: false,
        },
      ]
        .filter(({ visible, id }) => visible && this.actions.includes(id));
    },
    async handleAction({ method }, item) {
      this.setStaffLoading(item.id, true);

      try {
        await this[method](item);
      } catch (e) {
        this.$showServerError(e);
      } finally {
        this.setStaffLoading(item.id, false);
      }
    },
    handleOpenActions(row) {
      if (this.actions.includes('RESEND_INVITATION')) {
        this.loadResendInvitationData(row);
      }
    },
    editStaff(item) {
      return new Promise((callback) => {
        this.showEdit.show = true;
        this.showEdit.staff = item;
        this.showEdit.handle = callback;
      }).then(async (result) => {
        if (result) {
          this.$emit('updated');
        }

        this.showEdit.show = false;
        this.showEdit.staff = null;
      });
    },
    async delegate(item) {
      if (this.getStaffTroncsCount() > 0) {
        this.$alert({
          title: 'Error',
          message: 'Maximum amount of troncmasters is 2',
          applyButton: this.$t('form.buttons.ok'),
        });

        return;
      }

      if (
        await this.$confirm({
          title: this.$t('staffs.modal.confirmation'),
          message: `Are you sure you want to share ${this.$tl('form.labels.troncMaster', item.location.country)} rights?`,
          applyButton: this.$t('form.buttons.yes'),
          closeButton: this.$t('form.buttons.cancel'),
        })
      ) {
        await api.troncMasters.delegateStaff({
          locationId: item.location.id,
          staffId: item.id,
        });

        item.isTronc = true;
      }
    },
    async undelegate(item) {
      if (
        await this.$confirm({
          title: this.$t('staffs.modal.confirmation'),
          message: `Are you sure you want to revoke ${this.$tl('form.labels.troncMaster', item.location.country)} rights?`,
          applyButton: this.$t('form.buttons.yes'),
          closeButton: this.$t('form.buttons.cancel'),
        })
      ) {
        await api.troncMasters.undelegateStaff({
          locationId: item.location.id,
          staffId: item.id,
        });

        item.isTronc = false;
      }
    },
    openDeactivate(item) {
      return new Promise((callback) => {
        this.showDeactivate.show = true;
        this.showDeactivate.staff = item;
        this.showDeactivate.handle = callback;
      }).then(async (result) => {
        if (result) {
          this.$emit('updated');
        }

        this.showDeactivate.show = false;
        this.showDeactivate.staff = null;
      });
    },
    openGroups(item) {
      return new Promise((callback) => {
        this.showGroups.show = true;
        this.showGroups.staff = item;
        this.showGroups.handle = callback;
      }).then(async (result) => {
        if (result) {
          this.$emit('updated');
        }

        this.showGroups.show = false;
        this.showGroups.staff = null;
      });
    },
    openRegistrationDetails(item) {
      return new Promise((callback) => {
        this.showRegistrationDetails.show = true;
        this.showRegistrationDetails.staff = item;
        this.showRegistrationDetails.handle = callback;
      }).then(() => {
        this.showRegistrationDetails.show = false;
        this.showRegistrationDetails.staff = null;
      });
    },
    openIdInformation(item) {
      return new Promise((callback) => {
        this.showIdInformation.show = true;
        this.showIdInformation.staff = item;
        this.showIdInformation.handle = callback;
      }).then(() => {
        this.showIdInformation.show = false;
        this.showIdInformation.staff = null;
      });
    },
    openBankAccountDetails(item) {
      return new Promise((callback) => {
        this.showBankAccountDetails.show = true;
        this.showBankAccountDetails.staff = item;
        this.showBankAccountDetails.handle = callback;
      }).then(() => {
        this.showBankAccountDetails.show = false;
        this.showBankAccountDetails.staff = null;
      });
    },
    async viewAs(item) {
      const query = await this.loginAs(item);

      window.location.href = this.$router.resolve({
        name: 'token',
        query: {
          ...query,
          returnPath: this.$route.fullPath,
        },
      })?.href;
    },
    async sendRequestId({ id }) {
      if (
        await this.$confirm({
          title: this.$t('idInfoModal.confirm.title'),
          message: this.$t('idInfoModal.confirm.message'),
          applyButton: this.$t('form.buttons.send'),
          closeButton: this.$t('form.buttons.cancel'),
        })
      ) {
        try {
          await api.staff.sendRequestId({ staffId: id });

          this.$showSuccess();
        } catch (e) {
          this.$showServerError(e);
        }
      }
    },
    async cancelRequestId({ id }) {
      try {
        await api.staff.cancelRequestId({ staffId: id });

        this.$showSuccess();
      } catch (e) {
        this.$showServerError(e);
      }
    },
    async sendRequestBankDetails({ id }) {
      try {
        await api.staff.sendRequestBankDetails({ staffId: id });

        this.$showSuccess();
      } catch (e) {
        this.$showServerError(e);
      }
    },
    async cancelRequestBankDetails({ id }) {
      try {
        await api.staff.cancelRequestBankDetails({ staffId: id });

        this.$showSuccess();
      } catch (e) {
        this.$showServerError(e);
      }
    },
    openAddPayroll(item) {
      return new Promise((callback) => {
        this.showAddPayroll.show = true;
        this.showAddPayroll.staff = item;
        this.showAddPayroll.handle = callback;
      }).then(async (payrollId) => {
        if (payrollId) {
          item.payrollId = payrollId;
        }

        this.showAddPayrollId.show = false;
        this.showAddPayrollId.staff = null;
      });
    },
    async resetStripe(item) {
      if (
        await this.$confirm({
          title: this.$t('staffs.modal.confirmation'),
          message: 'Are you sure you want to reset user’s Stripe account? All data will be lost if user’s Stripe account is reset.',
          applyButton: this.$t('form.buttons.send'),
          closeButton: this.$t('form.buttons.cancel'),
        })
      ) {
        await api.stripe.removeStripeAccountStaff({
          locationId: item.location.id,
          staffId: item.id,
        });

        item.paymentStatus = null;
        item.stripeAccountId = null;
      }
    },
    openSharedCardTerms(item) {
      return new Promise((callback) => {
        this.showSharedCardTerms.show = true;
        this.showSharedCardTerms.handle = callback;
      }).then(async (result) => {
        if (result) {
          await api.locations.allowSharedCard({
            staffId: item.id,
            locationId: item.location.id,
          });

          this.$emit('updated');
        }

        this.showSharedCardTerms.show = false;
      });
    },
    async disallowSharedCard(item) {
      await api.locations.disallowSharedCard({
        staffId: item.id,
        locationId: item.location.id,
      });

      this.$emit('updated');
    },
    async resendInvitation(item) {
      await api.locations.sendRegistrationMessage({
        staffId: item.id,
        locationId: item.location.id,
      });

      this.loadResendInvitationData(item);
    },
    async changePhone(item) {
      this.changePhoneModal.staff = item;
      this.changePhoneModal.show = true;
    },
    async loadResendInvitationData({ id, location }) {
      this.resetResendInvitation();

      try {
        const {
          data: {
            lastSendTime,
            remainingAttempts,
          },
        } = await api.locations.getRegistrationMessage({
          staffId: id,
          locationId: location.id,
        });

        if (lastSendTime) {
          const timeout = this.getResendInvitationTimeout({
            lastSendTime,
            remainingAttempts,
          });

          if (timeout > 0) {
            this.registrationMessage.timer = new Timer(timeout, () => {
              if (remainingAttempts > 0) {
                this.registrationMessage.disabled = false;
              }

              this.registrationMessage.timer = null;
            });
          }
        }

        if (remainingAttempts > 0 && !this.registrationMessage.timer) {
          this.registrationMessage.disabled = false;
        }

        this.registrationMessage.remainingAttempts = remainingAttempts;
      } catch (e) {
        this.$showServerError(e);
      }
    },
    resetResendInvitation() {
      if (this.registrationMessage.timer) {
        this.registrationMessage.timer.stop();
      }

      this.registrationMessage.timer = null;
      this.registrationMessage.remainingAttempts = null;
      this.registrationMessage.disabled = true;
    },
    getResendInvitationTimeout({ lastSendTime, remainingAttempts }) {
      const currentTime = +new Date();
      const remainingTime = +parseDate(lastSendTime);

      if (remainingAttempts === 1) {
        return remainingTime + MINUTES_2 - currentTime;
      }

      if (remainingAttempts === 2) {
        return remainingTime + MINUTES_2 - currentTime;
      }

      return -1;
    },
    async resetOtpTryouts(staff) {
      try {
        await api.manager.resetOtpTryouts(staff);
        this.$showSuccess();
      } catch (e) {
        this.$showServerError(e);
      }
    },
  },
};
</script>
